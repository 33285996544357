@import "header";
@import "footer";
@import "cardjson";

/*-- Global --*/
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

body {
  background: $dark;
}

h1,
h2,
h3 {
  color: $jaune;
  font-family: "Lexend Peta", sans-serif;
  font-weight: 200;
}
h2 {
  margin-bottom: 40px;
}

.h2_separateurs {
  font-size: 1.3rem;
  font-family: "Lexend Peta", sans-serif;
  color: $light;
  text-align: center;
  text-transform: uppercase;
  padding-top: 30px;
}
p.visuels {
  padding-bottom: 5px;
}

.h3_logos {
  padding-top: 30px;
  font-size: 30px;
}

p {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
}
li {
  font-family: "Quicksand", sans-serif;
}

@media screen and (min-width: 780px) {
  .h2_separateurs {
    font-size: 2rem;
    padding-top: 40px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-weight: 140px;
  }
  p.visuels {
    padding-bottom: 20px;
  }
}

.container-separateur {
  background: linear-gradient(to bottom, rgba(255, 0, 0, 0), black),
    no-repeat center / cover url(/src/static/assets/img/BandesLigne.png);
  border-bottom: 1px solid red;
}
.separateur {
  min-height: 6vh;
}
.sous-titres {
  color: $jaune;
  font-family: "Lexend Peta", sans-serif;
  font-size: 2rem;
  font-weight: 200;
  text-align: center;
}

button {
  @include btnStyle;
}

button:hover {
  @include btnStyleHover;
}

.presentation {
  @include gridPersonalite;
  padding: 0 1rem;
}

.infos {
  @include gridBuild;
  padding: 1rem;
}

.infos_synthese {
  @include gridBuild;
  padding: 3rem;
}

.container-infos {
  margin: 10px auto;
  background: no-repeat center / cover
    url("/src/static/assets/img/fondGris.png");
}
.container-infos_dark {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@media screen and (min-width: 780px) {
  .container-infos_dark {
    max-width: 1600px;
    margin: 1rem auto;
    grid-gap: 2rem;
    padding: 0 2rem;
  }
  .container-infos {
    margin: 20px auto;
  }
  .infos {
    margin: 1rem auto;
    padding: 2rem;
  }
}

.box-infos-content {
  margin-top: 2rem;
}

.box-infos-content p {
  color: $light;
}

.box-presentation,
.box-infos-content {
  width: 100%;
  height: auto;
  font-size: 18px;
  color: $light;
  padding: 0 1rem;
  margin: 10px 0;
}

.box-presentation h2,
.box-infos-content h2 {
  font-size: calc(20px + 0.3vw + 5px);
  border-left: 3px solid $jaune;
  padding-left: 1rem;
}
.box-presentation p,
.box-infos-content p {
  margin: 1rem 0;
}

.box-presentation-img,
.box-infos-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box-siteweb {
  width: 100%;
}

.box-infos-content a {
  text-decoration: none;
  @include btnStyle;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
}

.box-infos-content a:hover {
  @include btnStyleHover;
}

.menu_agrandi {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
img.menu {
  display: block;
  margin: 0 auto;
  width: 80%;
  height: auto;
}
img.camionMEA {
  display: block;
  margin: 0 auto;
  padding-top: 5px;
  width: 90%;
  height: auto;
}
img.post_facebook {
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  width: 95%;
  height: auto;
}

img:hover {
  transform: scale(1.05);
  transition: all 1s;
}

// -- App_cell --

.logo_container {
  display: flex;
}

div.box-logoTF-img {
  padding-top: 10px;
}
.logoTF {
  max-width: 100%;
  height: auto;
}

img.processus {
  display: none;
  padding-top: 50px;
}
.mobile_none {
  display: none;
}
h3.mobile {
  margin-bottom: 30px;
  font-size: 30px;
}
h3.mobile_titre {
  margin-top: 40px;
  font-size: 30px;
}

@media screen and (min-width: 780px) {
  img.processus {
    display: block;
    width: 50vw;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
    transition: all 1s;
  }
  h3.mobile_none {
    display: block;
    padding-top: 10px;
    color: $jaune;
    font-family: "Lexend Peta", sans-serif;
    font-weight: 200;
    font-size: 35px;
  }
  h3.mobile {
    font-size: 35px;
  }
  h3.mobile_titre {
    margin-top: 50px;
    font-size: 35px;
  }
  .h3_logos {
    font-size: 35px;
  }
  div.box-logoTF-img {
    padding-left: 140px;
    padding-top: 50px;
  }
  .cell_img:hover {
    transform: scale(1.3);
  }
  .container-separateur {
    border-bottom: 3px solid red;
  }
  .separateur {
    min-height: 6vh;
  }
  .container-infos {
    padding: 30px;
  }
  .box-infos-content {
    padding-top: 70px;
  }
  img.camionMEA {
    padding-top: 20px;
  }
  .container_cell {
    margin-bottom: 30px;
  }
}

.container_cell .cell_img {
  width: 220px;
  height: 420px;
  margin: 10px;
  transition: all 1s;
}

.cell_img:hover {
  transform: scale(1.2);
}

.container_cell {
  display: flex;
  height: auto;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (min-width: 780px) {
  .container_cell {
    height: auto;
  }
  .cell_img {
    margin: 80 5 75 5;
  }
}
div.espacement {
  height: 100px;
  width: auto;
}
/*-- separateurs --*/

.container-cards {
  @include flexCenter;
  margin: 5rem 0;
  flex-wrap: wrap;
  padding: 0 2rem;
}

.card {
  width: 300px;
  height: 510px;
  flex-shrink: 0;
  border-radius: 15px;
  background: $light;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  margin: 3rem 60px;
}

.card-fond-img {
  height: 40%;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.cell_img {
  height: 50px;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.cell_card {
  width: 200px;
  height: 430px;
  flex-shrink: 0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.card-fond-img img {
  width: 300px;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-title {
  height: 5%;
  margin: 1.5rem 0;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  color: $rouge;
}

.card-info {
  text-align: justify;
  height: 30%;
  padding: 10px 30px 0 30px;
  line-height: 18px;
  font-size: 15px;
}

.card-box-btn {
  height: 15%;
  @include flexCenter;
}
.card-left-btn {
  height: 15%;
  @include flexLeft;
}
/*--Skills --*/

.container-skills {
  background: no-repeat center / cover
    url("/src/static/assets/img/fondGris.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.h3_skills {
  color: black;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  justify-content: center;
  margin-bottom: 20px;
}

.skills {
  background: $gray;
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
  border-radius: 20px;
  text-align: center;
  justify-content: center;
  width: 70vw;
}
.skills ul {
  list-style: none;
}
progress {
  width: 70%;
  height: 20px;
  background: $light;
}
progress::-webkit-progress-bar {
  background: $light;
}
progress::-webkit-progress-value {
  background: $dark_gray;
}

@media screen and (min-width: 780px) {
  .skills {
    max-width: 1500px;
    justify-content: space-around;
  }
}

/*-- Form --*/
